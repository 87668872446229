import instance from "./Interceptors";
// 登录
export const login = (params) => {
  return instance.post("/api/Login", params);
};
// 查询用户
export const GetUserInfo = (params) => {
  return instance.get("/api/GetUserInfo", params);
};
// 公司List
export const GetUserCompanyOpen = (params) => {
  return instance.get("/api/GetUserCompanyOpen", params);
};
// 公司projectList
export const GetMyIndex = (params) => {
  return instance.get("/api/GetMyIndex", params);
};
// 项目通知
export const GetProjectMsg = (params) => {
  return instance.get("/api/GetProjectMsg", params);
};
// 项目详情
export const GetProjectInfos = (params) => {
  return instance.get("/api/GetProjectInfos", params);
};
// 进度计划
export const GetPlanList = (params) => {
  return instance.post("/api/GetPlanList", params);
};
// 更新进度
export const AddRecord = (params) => {
  return instance.post("/api/AddRecord", params);
};
// 计划详情
export const GetPlanDetailTree = (params) => {
  return instance.get("/api/GetPlanDetailTree", params);
};
// 实景TreeList
export const GetModelLableSets = (params) => {
  return instance.get("/api/GetModelLableSets", params);
};
// 实景TreeInfo
export const GetModelLabelSetList = (params) => {
  return instance.get("/api/GetModelLabelSetList", params);
};
// 删除实景TreeInfo
export const DelModelLabelSet = (params) => {
  return instance.post("/api/DelModelLabelSet", params);
};
// 上传实景
export const UploadModelLable = (params) => {
  return instance.post("/api/UploadModelLable", params);
};
// 工程资料TreeList
export const GetProjectFileZTree = (params) => {
  return instance.get("/api/GetProjectFileZTree", params);
};
// 工程资料TreeInfo
export const GetDataFileList = (params) => {
  return instance.get("/api/GetDataFileList", params);
};
// 删除工程资料
export const DelDataFile = (params) => {
  return instance.post("/api/DelDataFile", params);
};
// 发送验证码
export const SendSMSAsync = (params) => {
  return instance.post("/api/SendSMSAsync", params);
};
// 注册账号
export const Register = (params) => {
  return instance.post("/api/Register", params);
};
// 找回密码
export const ForgetPwd = (params) => {
  return instance.post("/api/ForgetPwd", params);
};
// 获取申请记录
export const GetApplyRecord = (params) => {
  return instance.get("/api/GetApplyRecord", params);
};
// 根据企业编码查找企业
export const GetCompanyDeptAndJob = (params) => {
  return instance.get("/api/GetCompanyDeptAndJob", params);
};
// 根据项目编码查找项目
export const GetProjectDeptAndJob = (params) => {
  return instance.get("/api/GetProjectDeptAndJob", params);
};
// 申请加入企业
export const ApplyToJoinCompany = (params) => {
  return instance.post("/api/ApplyToJoinCompany", params);
};
// 申请加入项目
export const ApplyToJoinProject = (params) => {
  return instance.post("/api/ApplyToJoinProject", params);
};
// 催办
export const SetUrge = (params) => {
  return instance.post("/api/SetUrge", params);
};
// 撤销催办
export const DelApply = (params) => {
  return instance.post("/api/DelApply", params);
};
// 获取消息list
export const GetMessage = (params) => {
  return instance.get("/api/GetMessage", params);
};
// 处理消息
export const HandleMsg = (params) => {
  return instance.post("/api/HandleMsg", params);
};
// 删除消息
export const DelMsg = (params) => {
  return instance.post("/api/DelMsg", params);
};
// 修改密码
export const UpdatePwd = (params) => {
  return instance.post("/api/UpdatePwd", params);
};
// 修改头像
export const UploadUserImg = (params) => {
  return instance.post("/api/UploadUserImg", params);
};
// 修改个人中心
export const EditPersonalCenter = (params) => {
  return instance.post("/api/EditPersonalCenter", params);
};
// 项目部门tree
export const GetProjectdeptZTree = (params) => {
  return instance.get("/api/GetProjectdeptZTree", params);
};
// 项目部门下的人员
export const GetDeptUser = (params) => {
  return instance.get("/api/GetDeptUser", params);
};
// 待办
export const GetTaskTodo = (params) => {
  return instance.get("/api/GetTaskTodo", params);
};
//帮助中心 --树
export const GetControlData = (params) => {
  return instance.get("/api/GethelpdocZTree", params);
};
//帮助中心-- 查询详情
export const Findhelpdoc = (params) => {
  return instance.get("/api/Findhelpdoc", params);
};

//获取模型tree
export const GetWorkPlaneTree2 = (params) => {
  return instance.post("/api/GetWorkPlaneTree2", params);
};
//获取模型
export const FindWorkPlaneModel = (params) => {
  return instance.post("/api/FindWorkPlaneModel", params);
};
//获取视点
export const GetViewPoint = (params) => {
  return instance.post("/api/GetViewPoint", params);
};
//获取视点用户列表
export const GetViewPointUser = (params) => {
  return instance.get("/api/GetViewPointUser", params);
};
//模型视点管理列表
export const GetViewPointShare = (params) => {
  return instance.get("/api/GetViewPointShare", params);
};
//分享管理启用禁用
export const UpdateViewPointShareState = (params) => {
  return instance.post("/api/UpdateViewPointShareState", params);
};
//分享管理删除
export const DelViewPointShare = (params) => {
  return instance.post("/api/DelViewPointShare", params);
};

// 微信登录
export const MiniprogramLogin = (params) => {
  return instance.post("/api/MiniprogramLogin", params);
};

// 流程待办list
export const GetFormTask = (params) => {
  return instance.get("/api/GetFormTask", params);
};

// 进度待办list
export const GetPlanTask = (params) => {
  return instance.get("/api/GetPlanTask", params);
};

// 工单待办list
export const GetWorkOrderTask = (params) => {
  return instance.get("/api/GetWorkOrderTask", params);
};

// 流程表单info
export const FindFormApplyDetail = (params) => {
  return instance.get("/api/FindFormApplyDetail", params);
};

// 工单表单info
export const GetWorkOrderInfo = (params) => {
  return instance.get("/api/GetWorkOrderInfo", params);
};

// 工单表单进度上报
export const HandelWorkOrder = (params) => {
  return instance.post("/api/HandelWorkOrder", params);
};
// 工单表单申请审核
export const UpdateWorkOrderTime = (params) => {
  return instance.post("/api/UpdateWorkOrderTime", params);
};

//获取单个视点/选择集
export const GetSelectOrView = (params) => {
  return instance.post("/api/GetSelectOrView", params);
};

// 获取单个模型信息
export const GetModelInfo = (params) => {
  return instance.post("/api/GetModelInfo", params);
};
// 获取单个项目进度
export const GetPlanOne = (params) => {
  return instance.post("/api/GetPlanOne", params);
};

// 获取多个模型信息
export const GetModelInfos = (params) => {
  return instance.post("/api/GetModelInfos", params);
};
// 获取单个进度详情
export const GetPlanDetailOne = (params) => {
  return instance.post("/api/GetPlanDetailOne", params);
};
// 更新进度内容
export const UpdateNoiceImage = (params) => {
  return instance.post("/api/UpdateNoiceImage", params);
};

//获取表单申请列表
export const GetFormApply = (params) => {
  return instance.get("/api/GetFormApply", params);
};
//获取安全清单评论列表
export const GetFromSafeComment = (params) => {
  return instance.get("/api/GetFromSafeComment", params);
};
// 删除安全清单评论
export const DelFromSafeComment = (params) => {
  return instance.post("/api/DelFromSafeComment", params);
};
// 安全清单评论
export const AddFromSafeComment = (params) => {
  return instance.post("/api/AddFromSafeComment", params);
};
// 新增安全清单表单模板
export const GetFullFormTemp = (params) => {
  return instance.get("/api/GetFullFormTemp", params);
};
export const GetFormAuditFlowList = (params) => {
  return instance.get("/api/GetFormAuditFlowList", params);
};
export const GetOneFormAuditFlow = (params) => {
  return instance.get("/api/GetOneFormAuditFlow", params);
};
export const GetOneFormTemp = (params) => {
  return instance.get("/api/GetOneFormTemp", params);
};
export const GetProUsersList = (params) => {
  return instance.get("/api/GetProUsersList", params);
};
export const AddSafeFormApply = (params) => {
  return instance.post("/api/AddSafeFormApply", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const DelFormApply = (params) => {
  return instance.post("/api/DelFormApply", params);
};
export const PressFormApply = (params) => {
  return instance.post("/api/PressFormApply", params);
};
export const CancelFormApply = (params) => {
  return instance.post("/api/CancelFormApply", params);
};
export const SubmitAudit = (params) => {
  return instance.post("/api/SubmitAudit", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const AddFormApplyLink = (params) => {
  return instance.post("/api/AddFormApplyLink", params);
};
export const GetNewFormApplyFieldSafe = (params) => {
  return instance.get("/api/GetNewFormApplyFieldSafe", params);
};
export const GetNetDateTime = (params) => {
  return instance.get("/api/GetNetDateTime", params);
};

export const IsReject = (params) => {
  return instance.get("/api/IsReject", params);
};
