import router from "../router/index"
import store from "../store/index"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ ease: 'ease', speed: 800 });

// vuex重新赋值
if (sessionStorage.getItem("store")) {
    store.replaceState(
        Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store")))
    );
}
//在页面刷新时将vuex里的信息保存到sessionStorage里
window.addEventListener("beforeunload", () => {
    sessionStorage.setItem("store", JSON.stringify(store.state));
});

var whiteList=['/','/GetBackPassword','/xtbInfo','/AddUser','/login']

router.beforeEach((to, from, next) => {
    if (whiteList.includes(to.path)) {
        if (store.state.token.length > 0 && JSON.parse(localStorage.isLogin)) {
            next({ path: '/project' });
        } else {
            next();
        }
    } else {
        if (store.state.token.length == 0 && !JSON.parse(localStorage.isLogin)) {
            next({
                path: "/"
            })
        } else {
            next();
        }
    }
})

router.afterEach(transition => {
    // NProgress.done();
})

