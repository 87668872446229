import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import {
  GetProjectInfos
} from "@/api"
export default new Vuex.Store({
  state: {
    token: "",
    uid: "",
    pid: "",
    pImg: 'https://lgapi.eabim.cn/',
    // pImg: 'http://192.168.1.66:64305',
    // pImg: 'http://192.168.1.225:64305',
    pName: "",
    qid: "",
    schoolId: "",
    username: "",
    userImg: "",
    roleList: [],
    PlanList: [],
    // baseUrl: 'http://192.168.1.66:64305',
    // 温帅韬
    // baseUrl: 'http://192.168.1.225:64305',
    baseUrl: 'https://lgapi.eabim.cn/',
    // baseUrl: 'http://192.168.1.237:64305'
  },
  
  mutations: {
    SET_SCHOOLID: (store, id) => {
      store.schoolId = id;
    },
    SET_SCHOOLROLE: (store, role) => {
      store.roleList = role;
    },
  },
  getters: {
    qid: (state) => {
      return state.qid;
    },
    pid: (state) => {
      return state.pid;
    },
  },

  actions: {
    GetProjectInfos({
      state,
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        GetProjectInfos({
          params: {
            id: state.pid,
          }
        }).then(res => {
          resolve(res);
        })
      })
    },
  },
  modules: {}
})