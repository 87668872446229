<template>
  <div id="app">
    <v-touch v-on:swipeleft="onSwipeLeft" v-on:swiperight="onSwipeRight" class="wrapper">
      <keep-alive>
        <transition :name="transitionName" mode="out-in">
          <router-view class="menu-container" ref="menuContainer" />
        </transition>
      </keep-alive>
      <transition :name="transitionName" mode="out-in">
        <van-tabbar style="z-index: 99999999;" v-model="active" v-if="showMenu">
          <van-tabbar-item @click="$router.push({ path: '/home' })">
            <span>项目</span>
            <template #icon="props">
              <div class="myImg" :style="`background-image:url(${props.active ? icon.active : icon.inactive
              })`"></div>
            </template>
          </van-tabbar-item>
          <van-tabbar-item @click="$router.push({ path: '/home/menu/backlog' })">
            <span>待办</span>
            <template #icon="props">
              <div class="myImg" :style="`background-image:url(${props.active ? icon.active2 : icon.inactive2
              })`"></div>
            </template>
          </van-tabbar-item>
          <van-tabbar-item @click="$router.push({ path: '/home/menu/member' })">
            <span>成员</span>
            <template #icon="props">
              <div class="myImg" :style="`background-image:url(${props.active ? icon.active3 : icon.inactive3
              })`"></div>
            </template>
          </van-tabbar-item>
          <van-tabbar-item @click="$router.push({ path: '/home/menu/my' })">
            <span>我的</span>
            <template #icon="props">
              <div class="myImg" :style="`background-image:url(${props.active ? icon.active4 : icon.inactive4
              })`"></div>
            </template>
          </van-tabbar-item>
        </van-tabbar>
      </transition>
    </v-touch>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: "fade",
      appMenuList: ["/home", "/discussion", "/schoolResource", "/home/menu/My"],
      menuScroll: "",
      foodsScroll: "",
      showMenu: false,
      active: 0,
      names: "",
      icon: {
        active: require("./assets/mobileImg/bottom/项目主页-选中.png"),
        inactive: require("./assets/mobileImg/bottom/项目主页-未选中.png"),
        active2: require("./assets/mobileImg/bottom/待办-选中.png"),
        inactive2: require("./assets/mobileImg/bottom/待办-未选中.png"),
        active3: require("./assets/mobileImg/bottom/成员-选中.png"),
        inactive3: require("./assets/mobileImg/bottom/成员-未选中.png"),
        active4: require("./assets/mobileImg/bottom/我-选中.png"),
        inactive4: require("./assets/mobileImg/bottom/我-未选中.png"),
      },
    };
  },
  methods: {
    onSwipeRight() {
      // this.transitionName = "slide-right";
      // this.active = this.active - 1;
      // if (this.active < 0) {
      //   this.active = 3;
      // }
      // console.log(this.active);
      // console.log(this.appMenuList[this.active]);
      // this.$router.replace(
      //   {
      //     path: this.appMenuList[this.active],
      //   },
      //   (onComplete) => {},
      //   (onAbort) => {}
      // );
      this.$router.go(-1);
    },
    onSwipeLeft() {
      // this.transitionName = "slide-left";
      // this.active = this.active + 1;
      // console.log(this.active);
      // if (this.active >= this.appMenuList.length) this.active = 0;
      // this.$router.push({
      //   path: this.appMenuList[this.active],
      // });
      this.$router.go(1);
    },
    _initScroll() {
      // this.menuScroll = this.$BScroll(this.$refs.menuContainer, {});
    },
  },
  mounted() {
  },
  created() {
    // this._initScroll();
  },
  watch: {
    $route: {
      handler(to, from) {
        switch (to.name) {
          case "backlog":
          case "backlog":
            this.active = 1;
            break;
          case "member":
            this.active = 2;
            break;
          case "my":
            this.active = 3;
            break;
          default:
            this.active = 0;
            break;
        }
        this.showMenu = to.meta.menu || false;
        const tabPath = [
          "/home",
          "/discussion",
          "/schoolResource",
          "/My",
          "/discussion/joint",
          "/home/menu",
          "/home/menu/my",
        ];
        if (
          tabPath.some((item) => item == to.path) &&
          tabPath.some((item) => item == from.path)
        ) {
          this.transitionName = "fade";
        } else {
          const toDepth = to.path.split("/").length;
          const fromDepth = from.path.split("/").length;
          this.transitionName =
            toDepth < fromDepth ? "slide-right" : "slide-left";
        }
      },
      // 深度观察监听
      deep: true,
    },
    names: (val, oldval) => { },
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}


.van-sticky--fixed {
  z-index: 9999999;
}

.van-tabs__nav--line {
  height: 90%
}

.nowrap {
  overflow: hidden;
  font-size: 22px;
  text-overflow: ellipsis;
  // text-align: center;
  white-space: nowrap;
}

.footerBtn {
  position: fixed;
  width: 100%;
  bottom: 10px;
}

/deep/#nprogress .bar {
  top: 1.2rem !important;
}

.myImg {
  width: 35px;
  height: 35px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.imgdiv {
  width: 80px;
  height: 80px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flexLR {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/deep/body .van-collapse-item__content {
  overflow: auto;
}

.wrapper {
  height: 100%;

  .menu-container {
    height: 100%;
  }
}

.van-tabs {
  height: 100%;
}

.decoration {
  position: relative;
  font-weight: bold;
  z-index: 1;
  font-size: 24px;

  &::before {
    content: "";
    left: 0;
    top: 70%;
    position: absolute;
    width: 100%;
    opacity: 0.3;
    background-color: #003cff;
    height: 30%;
    z-index: -1;
  }
}

#app {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

body {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.footerRight {
  position: fixed;
  right: 5%;
  bottom: 8%;
  z-index: 2;
}

.warning_notice {
  width: 700px;
  margin: 20px auto;
  box-shadow: 0 3px 10px rgb(241, 220, 220);
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;

  .notice_table {
    text-align: center;

    .table_header {
      height: 60px;
      line-height: 60px;
      color: #14a8e9;

      p {
        float: left;
        width: 25%;
      }
    }

    .table_content {
      height: 70px;
      line-height: 70px;
      color: #333;
      font-size: 18px;
      border-top: 1px solid #ccc;

      p {
        float: left;
        width: 25%;
      }
    }
  }
}

.project_info {
  width: 700px;
  background: #fff;
  border-radius: 10px;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 20px 20px;
  overflow: hidden;
  color: #666;
  box-shadow: 0 3px 10px rgb(241, 220, 220);

  p {
    width: 50%;
    height: 50px;
    line-height: 50px;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      font-weight: 700;
    }
  }
}

.content {
  height: auto;
  position: relative;
  overflow: auto;
}

.project_img {
  width: 700px;
  height: 330px;
  border-radius: 10px;
}

.mini_title {
  margin-top: 20px;
  font-size: 24px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-left: 10px solid #14a8e9;
  overflow: hidden;

  div:nth-child(1) {
    font-weight: 700;
    margin-left: 10px;
    float: left;
  }

  div:nth-child(2) {
    float: right;
    font-size: 20px;
    color: #999;
  }
}

.myFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-left-enter-active {
  animation: slideLeft 0.4s;
}

.slide-right-enter-active {
  animation: slideRight 0.4s;
}

.slide-bottom-enter-active {
  animation: slideBottom 0.4s;
}

.fade-enter-active {
  transition: all ease 0.2s;
}

.fade-enter {
  opacity: 0;
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/deep/ .van-tabbar--fixed {
  position: absolute;
}

.myDiv {
  width: 100%;
  min-height: 260px;
  box-shadow: 0 0 6px 5px #f7f7f7;
  // border-radius: 20px;
  padding: 20px 40px;
  background-color: white;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.footer {
  background-color: white;
  z-index: 999;
  width: 100%;
  height: 100px;
  border-top: 1px solid #d8d8d8;
  position: fixed;
  padding: 0 10%;
  bottom: 0;
  left: 0;

  .footerdiv {
    height: 100%;

    img {
      width: 50px;
      height: 50px;
    }
  }
}

/deep/.van-pull-refresh__track {
  height: 100%;
}

/deep/.van-sticky--fixed {
  top: 20% !important;
}

.text_gary {
  color: #adadad;
}

.m_top20 {
  margin-top: 20px;
}

.slide-top-enter-active {
  animation: slideTop 0.4s;
}

.slide-top-leave-active {
  animation: outTop 0.4s;
}

@keyframes slideTop {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes outTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.floatL {
  float: left;
}

.floatR {
  float: right;
}
</style>
