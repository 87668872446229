import Vue from "vue";
import App from "./App.vue";
import "@/utils/RoleRouter"
import router from "./router";
import store from "./store/index";
// import echarts from 'echarts'
import 'lib-flexible/flexible'
import touch from 'vue-directive-touch';
Vue.use(touch);
import VueTouch from 'vue-touch'
VueTouch.config.swipe = {
    threshold: 300 //手指左右滑动距离
}
Vue.use(VueTouch, { name: 'v-touch' })

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)


// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'

import {
    Button,
    Form,
    Field,
    NavBar,
    Icon,
    Tabbar,
    TabbarItem,
    Empty,
    Tab,
    Tabs,
    Collapse,
    CollapseItem,
    Popup,
    Search,
    Picker,
    Cell,
    CellGroup,
    Toast,
    Calendar,
    Area,
    Uploader,
    Badge,
    Notify,
    Loading,
    Checkbox,
    PullRefresh,
    DropdownMenu,
    DropdownItem,
    Sidebar,
    SidebarItem,
    ActionSheet,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    RadioGroup,
    Radio,
    Tag,
    Grid,
    GridItem,
    Image,
    Rate,
    Panel,
    CheckboxGroup,
    Popover,
    Switch,
    Cascader,
    Col,
    Row,
    List,
    SwipeCell,
    Stepper,
    Dialog,
    Overlay,
    Step,
    Steps,
    Progress,
    Slider
} from 'vant';
Vue.use(Slider).use(Step).use(Progress).use(Steps).use(Overlay).use(Stepper).use(Dialog).use(SwipeCell).use(List).use(Col).use(Row).use(Cascader).use(Switch).use(Popover).use(CheckboxGroup).use(Panel).use(Rate).use(Image).use(Grid).use(GridItem).use(Tag).use(CellGroup).use(RadioGroup).use(Radio).use(GoodsAction).use(GoodsActionIcon).use(GoodsActionButton).use(ActionSheet).use(Sidebar).use(SidebarItem).use(DropdownItem).use(DropdownMenu).use(PullRefresh).use(Button).use(Checkbox).use(Loading).use(Badge).use(Uploader).use(Area).use(Calendar).use(Cell).use(Picker).use(Search).use(Form).use(Field).use(NavBar).use(Icon).use(Tabbar).use(TabbarItem).use(Empty).use(Tab).use(Tabs).use(Collapse).use(CollapseItem).use(Popup);

import 'nprogress/nprogress.css'

import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Notify = Notify;
Vue.prototype.$CodeMessage = (data, text,is) => {
    if (data.Code > 0) {
        Toast(text || data.Message);
    } else {
        Toast(is?text:data.Message);
    }
};

// 指令权限配
Vue.directive("activeBtn", {
    inserted: function (el, binding, vnode) {
        const { value: activerole } = binding;
        if (store.state.roleList.length > 0 || store.state.roleList != null) {
            let is = store.state.roleList.some(el => el == activerole);
            if (!is) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        } else {
            store.state.roleList = [];
        }
    }
})

// import './assets/js/rem.js';
import './assets/css/style.css';

Vue.config.productionTip = false;


// axios.defaults.baseURL = 'http://106.14.46.99:8029'; //路径

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");