import axios from 'axios'
import router from '../router/index'
import { Toast } from 'vant';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ ease: 'ease', speed: 800 });

var instance = axios.create({
    // baseURL: "http://192.168.1.225:64305",
    // baseURL: "http://192.168.1.66:64305",
    baseURL: "https://lgapi.eabim.cn/",
});
instance.interceptors.request.use(function (config) {
    let token = localStorage.token;
    NProgress.start();
    if (token && token != null) {
        config.headers.token = token;
    }
    return config;
}, function (error) {
    Toast.fail('请求超时');
    return Promise.reject(error);
});

// 添加一个响应拦截器
instance.interceptors.response.use(function (response) {
    NProgress.done();
    const data = response.data;
    if (data.Code == -1) {
        //未登录
        Toast.fail("登录信息已失效，请重新登录");
        localStorage.setItem('isLogin', false);
        localStorage.setItem('uid', '');
        router.push('/');
    }
    return data;
}, function (error) {
    NProgress.done();
    Toast.fail('响应超时');
    // localStorage.setItem('isLogin', false);
    // localStorage.setItem('uid', '');
    // router.push('/');
    // return Promise.reject(error);
})

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise}
 */
function getBlob(url) {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response);
            }
        };

        xhr.send();
    });
}
/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        const body = document.querySelector("body");

        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        // fix Firefox
        link.style.display = "none";
        body.appendChild(link);

        link.click();
        body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
    }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
export const download = (url, filename) => {
    getBlob(url).then(blob => {
        saveAs(blob, filename);
    });
};


//添加巡检记录
export const AddInspectionRecord = params => {
    return instance.post("/api/AddInspectionRecord", params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}


export default instance;
