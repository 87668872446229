import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const login = () =>
    import("@/views/Login");
const home = () =>
    import('@/views/Home/index')

const router = new Router({
    mode: "hash",
    // mode: "history",
    // base: process.env.BASE_URL,
    routes: [{
            path: "/",
            name: "login",
            component: login,
            meta: {
                menu: false
            },
        },
        {
            path: "/login",
            name: "login",
            component: login,
            meta: {
                menu: false
            },
        },
        {
            path: "/xtbInfo",
            name: "xtbInfo",
            component: () => {
                return import("@/views/Login/xtbInfo")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/project",
            name: "project",
            component: () => {
                return import("@/views/project")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/project/applyProject",
            name: "applyProject",
            component: () => {
                return import("@/views/project/applyProject")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/project/applyCompany",
            name: "applyCompany",
            component: () => {
                return import("@/views/project/applyCompany")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/model",
            name: "model",
            component: () => {
                return import("@/views/Model")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/xzj",
            name: "Xzj",
            component: () => {
                return import("@/views/Model/Xzj")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/administration",
            name: "administration",
            component: () => {
                return import("@/views/Model/administration")
            },
            meta: {
                menu: false
            },
        },
        {
            path: "/project/apply/applyInfo",
            name: "applyInfo",
            component: () => {
                return import("@/views/project/applyInfo")
            },
            meta: {
                menu: false,
                isHead: true
            },
        },
        {
            path: "/home",
            name: "home",
            meta: {
                menu: true
            },
            component: home,
            redirect: "/home/menu",
            children: [{
                    path: "/home/menu",
                    name: "dd_menu",
                    component: () => {
                        return import("@/views/Home/DD_menu")
                    },
                    meta: {
                        menu: true
                    },
                },
                {
                    path: "/home/menu/projectInfo",
                    name: "projectInfo",
                    component: () => {
                        return import("@/views/Home/DD_projectInfo")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    },
                },
                {
                    path: "/home/menu/schedule",
                    name: "schedule",
                    component: () => {
                        return import("@/views/Home/DD_schedule")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    },
                },
                {
                    path: "/home/menu/engineering",
                    name: "engineering",
                    component: () => {
                        return import("@/views/Home/DD_engineering")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    },
                },
                //安全管理
                {
                    path: "/home/menu/SecurityPatrol",
                    name: "SecurityPatrol",
                    component: () => {
                        return import("@/views/Home/DD_SecurityPatrol")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    }
                },
                {
                    path: "/home/menu/addSecurity",
                    name: "addSecurity",
                    component: () => {
                        return import("@/views/Home/DD_addSecurity")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    }
                },
                {
                    path: "/home/menu/auditInfo",
                    name: "auditInfo",
                    component: () => {
                        return import("@/views/Home/DD_auditInfo")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    }
                },
                {
                    name: "SecurityInfo",
                    path: "/home/menu/SecurityInfo",
                    component: () => {
                        return import("@/views/Home/SecurityInfo")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    },
                },
                //质量管理
                {
                    path: "/home/menu/QualityPatrol",
                    name: "QualityPatrol",
                    component: () => {
                        return import("@/views/Home/DD_QualityPatrol")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    }
                },
                {
                    path: "/home/menu/addQuantity",
                    name: "addQuantity",
                    component: () => {
                        return import("@/views/Home/DD_addQuantity")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    }
                },
                {
                    name: "QuantityInfo",
                    path: "/home/menu/QuantityInfo",
                    component: () => {
                        return import("@/views/Home/QuantityInfo")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    },
                },
                {
                    path: "/home/menu/QauditInfo",
                    name: "QauditInfo",
                    component: () => {
                        return import("@/views/Home/DD_QauditInfo")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    }
                },
                //
                {
                    path: "/home/menu/sceneryList",
                    name: "sceneryList",
                    component: () => {
                        return import("@/views/Home/DD_sceneryList")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    }
                },
                {
                    path: "/home/menu/scenery",
                    name: "scenery",
                    component: () => {
                        return import("@/views/Home/DD_scenery")
                    },
                    meta: {
                        menu: false,
                        isHead: true
                    },
                },
                {
                    path: "/home/menu/message",
                    name: "message",
                    component: () => {
                        return import("@/views/Home/DD_message")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    },
                },
                {
                    path: "/home/menu/my",
                    name: "my",
                    component: () => {
                        return import("@/views/Home/DD_My")
                    },
                    meta: {
                        menu: true,
                        isHead: false,
                    },
                },
                {
                    path: "/home/menu/member",
                    name: "member",
                    component: () => {
                        return import("@/views/Home/DD_member")
                    },
                    meta: {
                        menu: true,
                        isHead: false,
                    },
                },
                {
                    path: "/home/menu/backlog",
                    name: "backlog",
                    component: () => {
                        return import("@/views/Home/DD_backlog")
                    },
                    meta: {
                        menu: true,
                        isHead: false,
                    },
                },
                {
                    path: "/home/menu/bdInfo",
                    name: "bdInfo",
                    component: () => {
                        return import("@/views/Home/DD_backlog/bdInfo")
                    },
                    meta: {
                        menu: true,
                        isHead: true,
                        isHeadBj: true
                    },
                },
                {
                    path: "/home/menu/gdInfo",
                    name: "gdInfo",
                    component: () => {
                        return import("@/views/Home/DD_backlog/gdInfo")
                    },
                    meta: {
                        menu: false,
                        isHead: true,
                        isHeadBj: true
                    },
                },
            ]
        },
        {
            path: "/My/Setting",
            name: "MySetting",
            meta: {
                menu: false
            },
            component: () => {
                return import("@/views/My/Setting")
            }
        },
        {
            path: "/My/UpdatePassword",
            name: "UpdatePassword",
            meta: {
                menu: false
            },
            component: () => {
                return import("@/views/My/UpdatePassword")
            }
        },
        {
            path: "/My/Administration",
            name: "MyAdministration",
            meta: {
                menu: false
            },
            component: () => {
                return import("@/views/My/Administration")
            }
        },
        {
            path: "/GetBackPassword",
            name: "GetBackPassword",
            meta: {
                menu: false
            },
            component: () => {
                return import("@/views/Login/GetBackPassword")
            }
        },
        {
            path: "/AddUser",
            name: "AddUser",
            meta: {
                menu: false
            },
            component: () => {
                return import("@/views/Login/AddUser")
            }
        },
    ]
});

// // 路由守卫
// const enterpriseRoute = [
//     "enterprisemanagement",
//     "enterpriseproject",
//     "enterprisepersonnel",
//     "operationinfo"
// ];
// const projectRoute = [
//     "usermanagement",
//     "postpermissions",
//     "datadirectory",
// ];
// router.beforeEach((to, from, next) => {
//     if (to.name == null) {
//         next({
//             path: "/error404"
//         });
//     }
//     if (
//         to.name.toLowerCase() == "screentic" ||
//         to.name.toLowerCase() == "viewmodel"
//     ) {
//         //pc端不需要token
//         next();
//     } else if (to.name.toLowerCase() != "login") {
//         //pc端
//         if (!localStorage.getItem("token")) {
//             next({
//                 path: "/login"
//             });
//         } else {
//             if (enterpriseRoute.indexOf(to.name.toLowerCase()) >= 0) {
//                 IsEnterprise().then(res => {
//                     if (res.Data) {
//                         next();
//                     } else {
//                         next({
//                             path: "/error404"
//                         });
//                     }
//                 });
//             } else if (projectRoute.indexOf(to.name.toLowerCase()) >= 0) {
//                 IsProject({
//                     xid: localStorage.xid
//                 }).then(res => {
//                     if (res.Data) {
//                         next();
//                     } else {
//                         next({
//                             path: "/error404"
//                         });
//                     }
//                 });
//             } else {
//                 next();
//             }
//         }
//     } else {
//         //有token跳login
//         if (!localStorage.getItem("token")) {
//             next();
//         } else {
//             next({
//                 path: from.name
//             });
//         }
//     }
// });


export default router;